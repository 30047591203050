// Component Specific Variables
$app-sidebar-background-color: white;
$app-primary: #fe6c17;
$menu-link-active: #eaecf0;

$menu-icon-background-color-active:#ffcfb7;
$menu-icon-bg-color: white;
$menu-icon-svg-color:black;

$menu-title-color:black;

// Custom Variables
$checked-label-color: #1d2939;
$checked-label-background: #eaecf0;
$circle-background: #f2f4f7;
$custom-border-color: #eaecf0;
$headers-color: #101828;
$label-color: #344054;
$mat-header-row-background: #f9fafb;
$subtitle-color: #475467;
$selected-dates-btn-color: #667085;

@mixin font-properties($color,$weight, $size, $line-height) {
    color: $color;
    font-weight: $weight;
    font-size: $size;
    line-height: $line-height;
}

@mixin h1-heading-typo {
     @include font-properties($headers-color,600, 30px, 38px);
}

@mixin h2-heading-typo {
    @include font-properties( $headers-color,600, 18px, 24px);
}

@mixin paragraph-typo {
     @include font-properties($subtitle-color, 400, 14px, 20px);
}

@mixin label-typo {
     @include font-properties($label-color, 600, 16px, 24px);
}

@mixin menu-title-typo {
    @include font-properties($headers-color, 600, 16px, 24px);
}

@mixin mat-header-text {
     @include font-properties($subtitle-color, 500, 12px, 18px);
}

.subtitle-typo {
     @include font-properties($subtitle-color,400, 16px, 24px);
}

.mat-cell-typo-500 {
     @include font-properties($headers-color, 500, 14px, 20px);
}

