@import "~animate.css/animate.css";
@import "custom-variables.scss";

html,
body {

    height: 100%;
}
body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;

    h1 {
        @include h1-heading-typo
    }

    h2 {
       @include h2-heading-typo
    }

    p, span {
        @include paragraph-typo
    }

    label {
        @include label-typo
    }


    button > span {
        color: $selected-dates-btn-color;
        font-family: 'Inter', sans-serif;

        @include label-typo
    }
}

.mat-date-range-input-separator {
    width: 5px;
    overflow: hidden;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    height: 50px;
}

//*** App SideBar ***

@mixin menu-link() {
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem !important;
    margin-bottom: 1rem;
    .menu-title {
        @include menu-title-typo
    }

    .menu-icon {
        width: 30px;
        height: 30px;
        background-color: $menu-icon-bg-color;
        border-radius: 0.5rem;
        margin:10px;
        .svg-icon {
            svg {
                width: 25px !important;
                height: 25px !important;
                color: $menu-icon-svg-color;
            }
        }
    }
    &:hover{
        .menu-title{
            color:$menu-title-color !important;
        }
    }
}

@mixin menu-link-active() {
    background-color: $menu-link-active;

    &:hover {
        opacity: 1;
    }

    .menu-title {
        color:$menu-title-color;
    }
}

@mixin on-menu-link($isActive) {
    @if($isActive) {
        @include menu-link-active();
    }

    @else {
        @include menu-link();
    }
}

[data-kt-app-layout="light-sidebar"] {
    .app-sidebar {
        width: auto;
        background-color: $app-sidebar-background-color;

        .app-sidebar-logo {
            border-bottom: none !important;
        }

        .menu {
            max-height: 80vh;
            overflow-y: scroll;

            .menu-item {
                .menu-link {
                    @include on-menu-link(false);
                }

                .menu-link.active {
                    @include on-menu-link(true);
                }
            }
        }
    }
}


ngb-offcanvas-panel.offcanvas.offcanvas-end {
    width: auto;
}

.app-searcher .search-form {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 1250px)  {
    .app-content {
        min-width: 1250px !important;
        overflow: scroll;
    }
}

@media only screen and (min-width: 1250px)  {
    .app-content {
        min-width: 100% !important;
        overflow: hidden;
    }
}
